import React from "react";
import { useLocation } from "react-router";

import PublisherIcon from "@img/cloud-arrow-up-solid.svg";
import FederationIcon from "@img/federation.svg";
import NetworkIcon from "@img/network.svg";
import ServiceDesignerIcon from "@img/project-diagram-solid.svg";
import ReportIcon from "@img/reports.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useCanCreateBlockChart } from "@src/Hooks/Permissions/blockchart";
import { useCanViewFederation } from "@src/Hooks/Permissions/federation";

export function ServicesNavbar() {
  const { pathname } = useLocation();

  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const { allowed: canCreateBlockChart } = useCanCreateBlockChart(orgCtx);
  const { allowed: canViewFederation } = useCanViewFederation(orgCtx);

  const rootPath =
    pathname === "/app/user/settings" ? `/app/${defaultOrg}/services/` : `/app/${orgCtx}/services/`;
  return (
    <>
      <NavIcons>
        <NavItem linkTo={rootPath + "overview"} label="Services" icon={ServicesIcon} />
        <NavItem
          linkTo={rootPath + "service-designer"}
          label="Designer"
          icon={ServiceDesignerIcon}
        />
        <NavItem
          disabled={!canCreateBlockChart}
          linkTo={rootPath + "publisher"}
          label="Publisher"
          icon={PublisherIcon}
        />
        <NavItem linkTo={rootPath + "infrastructure"} label="Infrastructure" icon={NetworkIcon} />
        <NavItem linkTo={rootPath + "reports"} label="Reports" icon={ReportIcon} />
        <NavItem
          disabled={!canViewFederation}
          linkTo={rootPath + "federation"}
          label="Federations"
          icon={FederationIcon}
        />
      </NavIcons>
    </>
  );
}
