import { useQuery } from "react-query";

import { useApolloClient } from "@apollo/client";
import { relationships } from "@src/Auth/kratos";
import { OrgNameQuery, OrgNameQueryVariables } from "@src/Generated/graphql";
import OrgName from "@src/GraphQLQueries/OrgName.graphql";
import { useSession } from "@src/Session";

export function useUserOrgs(userId: string) {
  const client = useApolloClient();

  const {
    data: userOrgs,
    isLoading: loading,
    error
  } = useQuery<OrgNameQuery["org"][]>(
    ["userOrgs", userId],
    async () => {
      const tuples = await relationships.getRelationships({
        namespace: "Org",
        relation: "members",
        subjectSetNamespace: "User",
        subjectSetObject: userId,
        subjectSetRelation: ""
      });

      return Promise.allSettled(
        (tuples.data.relation_tuples || []).map(({ object: id }) =>
          client
            .query<OrgNameQuery, OrgNameQueryVariables>({ query: OrgName, variables: { id } })
            .then(res => res.data.org)
        )
      )
        .then(orgs => orgs.map(uo => uo.status === "fulfilled" && uo.value).filter(Boolean))
        .then(orgs => [
          ...new Map(orgs.filter(uo => uo.id && uo.displayName).map(uo => [uo.id, uo])).values()
        ]);
    },
    { enabled: !!userId }
  );

  return {
    userOrgs,
    loading,
    error
  };
}

export function useLoggedInUserOrgs() {
  const { session } = useSession();
  const userId = session?.identity?.id;

  return useUserOrgs(userId);
}
