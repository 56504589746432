import axios, { AxiosError } from "axios";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useMutation as useReactQueryMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { queryClient } from "@src/Clients/ReactQuery/reactQueryClient";
import { Loading } from "@src/Components/Loading/Loading";
import { ModalCloseOnButton } from "@src/Components/Modal/Modal";
import { initFormConfigFields } from "@src/DynamicForm/initFormConfig";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useToggle } from "@src/Hooks/toggle";
import { toastError } from "@src/MPNDashboard/Components/toastError";

import { headerNetworksKey, QueryCallbacks } from "../mpnRequests";
import { useFetchNetworkDetails } from "../networks";
import {
  NetworkForm,
  NetworkFormInner,
  networkFormSchema,
  serialiseNetworkForm,
  SuccessModal
} from "./NetworkFormComponents";

function editNetwork(networkId: string, network: object, { onComplete }: QueryCallbacks) {
  return axios
    .patch(
      `/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource/${networkId}`,
      network
    )
    .then(response => {
      onComplete();
      return response.data;
    });
}

export function EditNetworkForm() {
  const navigate = useNavigate();
  const orgCtx = useOrgCtx();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { network, loading } = useFetchNetworkDetails();

  const { state: showSuccess, toggle: toggleSuccess } = useToggle();

  const initialValues: NetworkForm = {
    fields: initFormConfigFields({
      fields: network?.config || [],
      isEdit: true,
      weightedSort: true
    })
  };

  const onCompleteEdit = useCallback(() => {
    queryClient.refetchQueries({ queryKey: [headerNetworksKey] });
    queryClient.refetchQueries({ queryKey: [orgCtx, network?.uid, "network-details"] });
    toggleSuccess();
  }, [network, orgCtx, toggleSuccess]);

  const {
    data,
    error: editNetworkError,
    mutate: editNetworkMutation
  } = useReactQueryMutation(["edit-network", network?.uid], (editedNetwork: object) =>
    editNetwork(network?.uid, editedNetwork, {
      onComplete: onCompleteEdit
    })
  );

  if (loading) return null;
  return (
    <>
      <Formik<NetworkForm>
        initialValues={initialValues}
        validationSchema={networkFormSchema}
        onSubmit={(values, formik) => {
          try {
            const serialisedValues = serialiseNetworkForm(values, network.chartversion, orgCtx);
            editNetworkMutation(serialisedValues);
            formik.setSubmitting(false);
          } catch (err) {
            toastError(err);
            console.error(err);
          }
        }}
      >
        {formikProps =>
          formikProps.isSubmitting ? (
            <Loading />
          ) : (
            <>
              <NetworkFormInner
                goBack={goBack}
                submitErrors={editNetworkError as AxiosError}
                isEdit
                {...formikProps}
              />
              <ModalCloseOnButton show={showSuccess}>
                <SuccessModal id={data?.id} isEdit />
              </ModalCloseOnButton>
            </>
          )
        }
      </Formik>
    </>
  );
}
