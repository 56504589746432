import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { PermissionArgs, usePermissionCheck } from "@src/Hooks/permissionsCheck";

type ProtectedRouteProps = {
  permissions: PermissionArgs;
  children?: ReactNode;
  redirectPath?: string;
};

export function ProtectedRoute({ permissions, redirectPath = "/", children }: ProtectedRouteProps) {
  const { allowed } = usePermissionCheck(permissions);

  if (!allowed && allowed != null) {
    return <Navigate to={redirectPath} replace />;
  }

  return allowed == null ? <></> : <>{children}</>;
}

type ProtectedDashboard = {
  dashboard: string;
  redirectPath?: string;
  children: ReactNode;
};

export function ProtectedDashboard({ dashboard, children, redirectPath }: ProtectedDashboard) {
  const orgCtx = useOrgCtx();
  const path = orgCtx ? `/app/${orgCtx}` : redirectPath;
  const perms = { relation: "view", objectId: dashboard, objectType: "Dashboard" };

  return (
    <ProtectedRoute permissions={perms} redirectPath={path}>
      {children}
    </ProtectedRoute>
  );
}
