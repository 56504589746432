import { UseSelectStateChange } from "downshift";
import { FormikErrors } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Dropdown, Select } from "@src/Components/Dropdown";
import { DropdownMenuItemWrapper } from "@src/Components/DropdownMenu";
import {
  FieldLabel,
  InputErrors,
  InputFieldWrapper,
  PaddedError
} from "@src/Components/Input/InputGroup";
import { NotFoundMessage } from "@src/Components/NotFound";
import { Skeleton } from "@src/Components/Skeleton";

const OrgSelect = styled(Select)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
`;

const OrgOption = styled(DropdownMenuItemWrapper)`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
const NoOrgsMessage = styled(NotFoundMessage)`
  font-style: italic;
`;

export const OrgId = styled.span`
  font-family: "Droid Sans Mono", "monospace", monospace;
  font-size: 12px;
  color: #858585;
`;

interface Org {
  id: string;
  displayName?: string;
}

interface OrgFormFieldProps {
  initialOrg?: string;
  orgs: Org[];
  hasOrgErrors: boolean;
  formErrors: FormikErrors<{ org: string }>;
  setFieldValue: (field: string, value: string) => void;
}

export function OrgFormField({
  initialOrg,
  orgs,
  hasOrgErrors,
  formErrors,
  setFieldValue
}: OrgFormFieldProps) {
  const getOrgDisplayName = useCallback((org: Org) => {
    if (!org) return null;
    return (
      <>
        {org.displayName} <OrgId>{org.id}</OrgId>
      </>
    );
  }, []);

  const getOrgKey = useCallback(({ id }: Org) => id, []);

  const onSelectOrg = useCallback(
    (change: UseSelectStateChange<Org>) => {
      setFieldValue("org", change.selectedItem.id);
    },
    [setFieldValue]
  );

  const selectedOrg = initialOrg ? orgs.find(({ id }) => id === initialOrg) : null;

  return (
    <>
      <FieldLabel htmlFor="org">organization</FieldLabel>

      <InputFieldWrapper>
        {hasOrgErrors ? (
          <OrgFieldError />
        ) : orgs.length > 0 ? (
          <>
            <Dropdown
              items={orgs}
              placeholder="-- select an organization --"
              initialSelectedItem={selectedOrg}
              onSelectedItemChange={onSelectOrg}
              getItemDisplayName={getOrgDisplayName}
              getItemKey={getOrgKey}
              SelectElement={OrgSelect}
              OptionElement={OrgOption}
              handleOverflow={false}
            />
            <PaddedError>
              <InputErrors errors={formErrors} name="org" />
            </PaddedError>
          </>
        ) : (
          <NoOrgsMessage>There are no Orgs available for selection.</NoOrgsMessage>
        )}
      </InputFieldWrapper>
    </>
  );
}

function OrgFieldError() {
  return (
    <NoOrgsMessage>
      Something happened while fetching available Orgs. Contact your System Admin if this issue
      persists.
    </NoOrgsMessage>
  );
}

export function OrgFormFieldSkeleton() {
  return (
    <>
      <FieldLabel htmlFor="org">
        <Skeleton width={70} height="14px" />
      </FieldLabel>
      <InputFieldWrapper>
        <Skeleton height="45px" />
        <PaddedError>
          <InputErrors errors={null} name="org" />
        </PaddedError>
      </InputFieldWrapper>
    </>
  );
}
