import { UseSelectStateChange } from "downshift";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Dropdown, Select } from "@src/Components/Dropdown";
import { DropdownMenuItemWrapper } from "@src/Components/DropdownMenu";
import { SelectorWrapper } from "@src/Components/OrgSelector";
import { HeaderItem } from "@src/Header/HeaderStyles";
import { useNetworkCtx } from "@src/Hooks/Context/networkCtx";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { SerialisedNetwork, useFetchNetworks } from "./networks";
import { useSelectNetwork } from "./selectNetwork";

const NetworkSelect = styled(Select)`
  padding: 8px;
  height: auto;
  font-size: 12px;
`;

const NetworkOptionElement = styled(DropdownMenuItemWrapper)`
  &:last-of-type:hover {
    background-color: ${({ theme }) => theme.green};
    color: white;
  }
`;

export function NetworkSelector() {
  const navigate = useNavigate();
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();
  const { selectNetwork } = useSelectNetwork();

  const { networks } = useFetchNetworks();

  const onSelectNetwork = useCallback(
    (change: UseSelectStateChange<SerialisedNetwork>) => {
      const network = change.selectedItem;
      if (change.selectedItem?.addNetwork) {
        navigate(`/app/${orgCtx}/mpn/network/add`);
      } else {
        selectNetwork(network.uid);
      }
    },
    [navigate, orgCtx, selectNetwork]
  );

  const getItemLabel = useCallback((selectedItem: SerialisedNetwork) => {
    return selectedItem?.displayname ?? "No Network";
  }, []);

  const dropdownOptions = [
    ...networks,
    { displayname: "Add Network", addNetwork: true, uid: "add-network" }
  ];

  if (networks.length === 0) return null;

  const selectedNetwork = networks.find(n => n.uid === networkCtx) || dropdownOptions.at(-1);

  return (
    <HeaderItem>
      Network:
      <SelectorWrapper>
        <Dropdown
          key={`${selectedNetwork.uid}-${selectedNetwork.displayname}`}
          SelectElement={NetworkSelect}
          OptionElement={NetworkOptionElement}
          items={dropdownOptions}
          initialSelectedItem={selectedNetwork}
          onSelectedItemChange={onSelectNetwork}
          getItemDisplayName={getItemLabel}
          getItemKey={getItemLabel}
        />
      </SelectorWrapper>
    </HeaderItem>
  );
}
